<template>
  <div class="favorite-game">
    <div class="favorite-game__list container-custom">
      <GameItems />
    </div>
  </div>
</template>
<script setup lang="ts">
const GameItems = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/list.vue'))
</script>
<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/game-center/index.scss"></style>
